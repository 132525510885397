import React from "react";
import { Container, Row } from "react-bootstrap";
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
    MdWork,
    MdSchool,
    MdStar,
    MdOutlineReceipt,
    MdArrowDownward,
    MdVideogameAsset,
  } from "react-icons/md";

function Timeline() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
        <VerticalTimeline>
        <VerticalTimelineElement
    iconStyle={{ background: 'rgb(12, 5, 19)', color: '#fff' }}
    icon={<MdArrowDownward />}
  />
<VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="November 2024"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdOutlineReceipt />}
  >
    <h3 className="vertical-timeline-element-title">PMI-ACP (Agile Certified Practitioner)</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
  </VerticalTimelineElement>
 
<VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="May 2024"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdOutlineReceipt />}
  >
    <h3 className="vertical-timeline-element-title">PMI-CAPM (Certified Associate in Project Management)</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
  </VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(12, 5, 19)', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date= "January 2023 - Present" 
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(12, 5, 19)', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">Project Coordinator</h3>
    <h4 className="vertical-timeline-element-subtitle">Acumen Technology</h4>
    <p align="left">
        <ul>
            <li>Managed technical projects including Microsoft 365, networking, office moves, server upgrades, & cloud migrations.</li>
            <li>Streamlined project management processes, reducing new-client onboarding time from 90 to 45 days and clearing a substantial backlog of 80 projects within 3 months.</li>
            <li>Standardized process and configuration documentation for consistency.</li>
            <li>Introduced PMI & Agile best practices for a hybrid project management environment.</li>
            <li>Consistently completed projects under budget through meticulous planning and execution.</li>
            </ul>

    </p>
  </VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="November 2022"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdOutlineReceipt />}
  >
    <h3 className="vertical-timeline-element-title">Microsoft 365 Certified: Enterprise Administrator Expert</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
  </VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="November 2022"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdOutlineReceipt />}
  >
    <h3 className="vertical-timeline-element-title">Microsoft 365 Mobility and Security (MS-101)</h3>
    <h4 className="vertical-timeline-element-subtitle">Exam</h4>
  </VerticalTimelineElement>

<VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="November 2022"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdOutlineReceipt />}
  >
    <h3 className="vertical-timeline-element-title">Microsoft 365 Identity and Services (MS-100)</h3>
    <h4 className="vertical-timeline-element-subtitle">Exam</h4>
  </VerticalTimelineElement>




      <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="August 2022"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdOutlineReceipt />}
  >
    <h3 className="vertical-timeline-element-title">Microsoft 365 Security Administrator Associate (MS-500)</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
  </VerticalTimelineElement>


    <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="August 2022"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdOutlineReceipt />}
  >
    <h3 className="vertical-timeline-element-title">Microsoft 365 Fundamentals (MS-900)</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    contentStyle={{ background: 'rgb(12, 5, 19)', color: '#fff' }}
    contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
    date= "July 2021 - December 2021" 
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(12, 5, 19)', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">Service Desk Analyst III</h3>
    <h4 className="vertical-timeline-element-subtitle">Acumen Technology</h4>
    <p align="left">
        <ul>
            <li>Project Coordinator; ensured projects maintained a healthy life cycle.</li>
            <li>Developed an Account Management PowerShell application in PowerShell streamling Active Directory administration.</li>
            <li>Knowledge Contributor for the Service Desk, consistently
                reviewing, and improving documentation.</li>
            <li>Maintained Exchange servers, Virtual Servers, FortiGate firewalls,
                ProofPoint, VPNs, networks, enterprise services, and SaaS
                applications.</li>
            <li>Administered Accounts and Identities, Conditional Access Policies, Multi-Factor
                Authentication, and group memberships in Azure Active Directory/Microsoft 365.</li>
            </ul>

    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    dateClassName= "date-color-override"
    date="June 2022"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">Process & Procedures Analyst</h3>
    <h4 className="vertical-timeline-element-subtitle">GrubRunner - Monthly Contract</h4>
    <p align="left">
        <ul>
            <li>Created standard operating procedures.</li>
            <li>Created technical disaster recovery documents.</li>
            </ul>

    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    dateClassName= "date-color-override"
    date="January 2022 - April 2022"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">Knowledge Base Administrator</h3>
    <h4 className="vertical-timeline-element-subtitle">Red Bull - Quarterly Contract</h4>
    <p align="left">
        <ul>
            <li>Redesigned existing knowledge base articles in ServiceNow.</li>
            <li>Used Microsoft Planner to create a project plan for new and existing articles.</li>
            <li>Created a knowledge base lifecycle to ensure documents are updated regularly.</li>
            </ul>

    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="August 2021"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdOutlineReceipt />}
  >
    <h3 className="vertical-timeline-element-title">Microsoft Azure Fundamentals (AZ-900)</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="July 2021"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdOutlineReceipt />}
  >
    <h3 className="vertical-timeline-element-title">CompTia A+</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="August 2019 - May 2021"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdSchool />}
  >
    <h3 className="vertical-timeline-element-title">Information Technology</h3>
    <h4 className="vertical-timeline-element-subtitle">Bachelor's Degree</h4>
    <p>
      Tennessee Technological University<br />
      3.84 GPA - Cum Laude
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    dateClassName= "date-color-override"
    date="October 2020 - January 2021"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">Technical Writer - Quarterly Contract</h3>
    <h4 className="vertical-timeline-element-subtitle">SimpleVoIP</h4>
    <p align="left">
        <ul>
            <li>Designed the Knowledge Base Article templates.</li>
            <li>Created & renewed Knowledge Articles.</li>
            </ul>

    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="March 2020"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdOutlineReceipt />}
  >
    <h3 className="vertical-timeline-element-title">Help Desk Institute: CSR</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    dateClassName= "date-color-override"
    date="February 2019 - July 2021"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">Service Desk Supervisor</h3>
    <h4 className="vertical-timeline-element-subtitle">Tennessee Technological University</h4>
    <p align="left">
        <ul>
            <li>Policies & Procedures Committee Chairman.</li>
            <li>IT Service Management Project Manager; designed the Knowledge Base, Service Catalog,
                Categories, Ticket Forms, and Workflows for the platform.</li>
            <li>Standardized IT documentation</li>
            <li>Managed and trained student-employee workforce.</li>
            </ul>

    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="March 2019"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdOutlineReceipt />}
  >
    <h3 className="vertical-timeline-element-title">ITIL v3 Foundations</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    dateClassName= "date-color-override"
    date="June 2018 - February 2019"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">Service Desk Analyst II</h3>
    <h4 className="vertical-timeline-element-subtitle">SAIC</h4>
    <p align="left">
        <ul>
            <li>Spearheaded the project for developing the Service Now Training platform. Tasks included; creating and maintaining a scrum task board, creating a baseline format and template as well as managing resources to get the project done in a timely manner</li>
            <li> Implemented Information Technology Infrastructure Library (ITIL) best practices into a
developing Multi-sourcing Service Integration (MSI) platform by taking a lead role in developing
Policies, Procedures, Job Aids and other documentations for processes within the Service Desk</li>
            </ul>

    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    dateClassName= "date-color-override"
    date="August 2017 - January 2018"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<MdVideogameAsset />}
  >
    <h3 className="vertical-timeline-element-title">Esports Manager</h3>
    <h4 className="vertical-timeline-element-subtitle">Team Aurora</h4>
    <p align="left">
        <ul>
            <li>Created recruitment process standards.</li>
            <li>Reached out and secured sponsorships.</li>
            <li>Created performance review standards.</li>
            <li>Organized skirmish and tournament matches.</li>
            </ul>

    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    dateClassName= "date-color-override"
    date="August 2015 - May 2018"
    iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
    icon={<MdWork />}
  >
    <h3 className="vertical-timeline-element-title">IT Support</h3>
    <h4 className="vertical-timeline-element-subtitle">McCoy Federal Credit Union</h4>
    <p align="left">
        <ul>
            <li>Managed Identity & Access through Active Directory and Group Policy.</li>
            <li>Installed and maintained IT solutions.</li>
            <li>Monitored service health for service insight and alerting with SolarWinds.</li>
            </ul>

    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--education"
    date="May 2013"
    dateClassName= "date-color-override"
    iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
    icon={<MdOutlineReceipt />}
  >
    <h3 className="vertical-timeline-element-title">CompTia A+</h3>
    <h4 className="vertical-timeline-element-subtitle">Certification</h4>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
    icon={<MdStar />}
  />
</VerticalTimeline>
        </Row>
      </Container>
    </Container>
  );
}
export default Timeline;
